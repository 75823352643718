import * as React from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import background from "../../images/services/nos-agents.jpg";
import "../../scss/style.scss";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesListBasic from "../../components/services/ServicesListBasic";
import ContactUs from "../../components/buttons/ContactUs";

const NosAgents = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Services -
        Agents de Sécurité qualifiés
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
          category="Services"
          title="Agents de Sécurité qualifiés"
          content=""
          background={background}
          position="left"
        />
        <div className="container pt-4 pt-md-10 pb-4 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-md-8">
              <div className="service service-single">
                <div className="content">
                  <h1>
                    Les agents de sécurité professionnels de notre société
                  </h1>
                  <p>
                    Notre société de sécurité est composée d'agents de sécurité
                    professionnels hautement qualifiés et expérimentés. Nous
                    avons une équipe solide d'agents de sécurité formés
                    continuellement pour faire face à toutes les situations
                    d'urgence possibles et pour agir rapidement et efficacement
                    en cas de besoin. Nous croyons que{" "}
                    <em>
                      la qualité de nos agents de sécurité est la clé pour
                      garantir la sécurité de nos clients.
                    </em>
                  </p>
                  <h2>
                    Qualifications et formations de nos agents de sécurité
                  </h2>
                  <p>
                    Tous nos <em>agents de sécurité</em> ont suivi une formation
                    approfondie et sont certifiés pour exercer leur métier. Ils
                    sont formés pour faire face à toutes les situations
                    d'urgence possibles et pour agir rapidement et efficacement
                    en cas de besoin. Nous fournissons régulièrement à notre
                    personnel des formations complémentaires pour les maintenir
                    à jour avec les dernières techniques de sécurité.
                  </p>
                  <h2>Compétences de nos agents de sécurité</h2>
                  <p>
                    Nos agents de sécurité sont équipés des dernières
                    technologies de surveillance pour garantir la sécurité de
                    nos clients <em>en tout temps</em>. Ils ont des compétences
                    variées dans différents domaines tels que :
                  </p>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <em>La surveillance</em>
                    </li>
                    <li className="list-group-item">
                      <em>Le contrôle d'accès</em>
                    </li>
                    <li className="list-group-item">
                      <em>La gestion des foules</em>
                    </li>
                    <li className="list-group-item">
                      <em>La sécurité de la propriété</em>
                    </li>
                    <li className="list-group-item">
                      <em>La protection VIP</em>
                    </li>
                  </ul>
                  <p>
                    Nous sommes fiers de dire que nos agents de sécurité sont
                    des professionnels expérimentés et passionnés par leur
                    travail. Nous recrutons et formons les meilleurs pour
                    assurer la sécurité de nos clients.
                  </p>
                  <h2>Notre engagement envers la satisfaction des clients</h2>
                  <p>
                    Nous sommes déterminés à offrir un service de sécurité
                    professionnel à nos clients. Nous travaillons en{" "}
                    <em>étroite collaboration avec nos clients</em> pour
                    comprendre leurs besoins en matière de sécurité et pour
                    élaborer des plans de sécurité adaptés à leurs besoins
                    spécifiques. Nous sommes enchantés de dire que nous avons
                    bâti une réputation de qualité et de professionnalisme
                    auprès de nos clients.
                  </p>
                  <p>
                    Nos agents de sécurité sont des professionnels hautement
                    qualifiés et expérimentés, formés pour faire face à toutes
                    les situations d'urgence possibles et pour agir rapidement
                    et efficacement en cas de besoin. Contactez-nous dès
                    aujourd'hui pour discuter de vos besoins en matière de
                    sécurité.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-2">
                <ContactUs theme="black" />
              </div>
            </div>
            <div className="col-md-3 offset-md-1 pt-4">
              <h3>Nos prestations :</h3>
              <ServicesListBasic />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default NosAgents;
